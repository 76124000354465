.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.example-enter {
  opacity: 0.01;
  max-height: 0;
}

.example-enter.example-enter-active {
  opacity: 1;
  max-height: 500px;
  overflow: hidden;
  transition: opacity 200ms ease-in, max-height 200ms ease-in;
}

.example-leave {
  opacity: 1;
  max-height: 500px;
}

.example-leave.example-leave-active {
  /*opacity: 0.01;*/
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in, max-height 0.5s ease-in;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-Regular.woff2) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-Medium.woff2) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-SemiBold.woff2) format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-Bold.woff2) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-ExtraBold.woff2) format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url(./fonts/Inter-Black.woff2) format("woff");
  font-weight: 900;
}

* {
  box-sizing: border-box;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-color: #2e3440;
  font-size: 21px;
  color: #eceff4;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px;
  color: #4c566a;
  font-size: 1rem;
}

a:visited {
  color: #81a1c1;
}

a:hover {
  color: #8fbcbb;
}

a.active {
  color: #eceff4;
}

a {
  color: #5e81ac;
}

#root {
  display: flex;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header {
  max-width: 100%;
  width: 1440px;
  padding: 100px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-header-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
}

.App-header-menu .logo {
  margin-right: auto;
}

.App-header-menu a {
  margin-left: 21px;
  font-size: 21px;
}

.App-link {
  color: #61dafb;
}

p {
  margin: 0;
}

.progress,
.error {
  position: absolute;
  top: 0;
}

.progress {
  color: greenyellow;
}

.error {
  color: #bf616a;
}

textarea,
input,
select {
  font-size: calc(10px + 2vmin);
  width: 100%;
  background-color: #3b4252;
  margin-top: 64px;
  color: #eceff4;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

button,
.typerheader--allquotes-link {
  background: none !important;
  color: #ebcb8b;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  border-bottom: 2px solid #ebcb8b;
}

button:disabled {
  color: #b1bace;
  border-bottom: 2px solid #4c566a;
  cursor: not-allowed;
}

button:hover:enabled {
  cursor: pointer;
  color: #a3be8c;
  border-bottom: 2px solid #a3be8c;
}

.typerheader--allquotes-link {
  text-decoration: none;
  color: #ebcb8b;
  border-bottom: 2px solid #ebcb8b;
}

.typerheader--allquotes-link:visited {
  color: #ebcb8b;
}

.typerheader--allquotes-link:hover {
  color: #ebcb8b;
}
